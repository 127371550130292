export const mktpGetWishlists = () => import('./get-wishlists');
export const mktpGetWishlistsDetails = () => import('./get-wishlists-details');
export const mktpCreateWishlist = () => import('./create-wishlist');
export const mktpAddItemWishlist = () => import('./add-item-wishlist');
export const mktpValidateWishlist = () => import('./validate-wishlist');
export const mktpDeleteItemWishlist = () => import('./delete-item-wishlist');
export const mktpDeleteWishlist = () => import('./delete-wishlist');
export const mktpSendOTPToken = () => import('./send-otp-token');
export const mktpGetDevices = () => import('./get-devices');
export const mktpGetOrderDetails = () => import('./get-order-details');
